body {
 
  font-family: apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    background-color: #f8f1e4;
    color: #5b4636;
  
    font-size: 18px; 
    font-family: apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
    
    color: black ;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #000000;
}

p {
    /*line-height: 1.7;*/
    line-height: 2;
}

a {
    -webkit-transition: 0.5s all ease;
    transition: 0.5s all ease;
}

a:hover {
    text-decoration: none;
}

img {
    max-width: 100%;
}

.d-table {
    width: 100%;
    height: 100%;
}

.d-table-cell {
    vertical-align: middle;
}

.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.pt-100 {
    padding-top: 20px;
}

.pb-70 {
    padding-bottom: 0px;
}

.pb-100 {
    padding-bottom: 100px;
}

button:focus {
    outline: 0;
}

.btn.focus,
.btn:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}
/*-- End Default CSS --*/



SLIDER START

/* Add this CSS in your CSS file or in a style tag */

/* .slider-fullscreen {
    height: 100vh;
  }
  
  .slider-image {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  
  .slide-caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
  }
  
  .awssld__timer { */
    /* display: none; /* Hide the timer bar */
  /* } 
  
  .awssld__content {
    position: relative;
    height: 100vh; Make the slide content take full viewport height */
  /* }
  
  @media (max-width: 1200px) {
    .slider-fullscreen {
      height: 80vh;
    }

    .slider-image {
      width: 100%;
      height: 80vh;
      object-fit: inherit;
  
    }
  
    .slide-caption h2 {
      font-size: 1.5em;
    }
  
    .slide-caption p {
      font-size: 1em;
    }
  }
  
  @media (max-width: 768px) {
    .slider-fullscreen {
      height: 60vh;
    }

    .slider-image {
      width: 100%;
      height: 60vh;
      object-fit: inherit;
  
    }
  
    .slide-caption {
      bottom: 10px;
      left: 10px;
      padding: 8px;
    }
  
    .slide-caption h2 {
      font-size: 1.2em;
    }
  
    .slide-caption p {
      font-size: 0.9em;
    }
  }
  
  @media (max-width: 576px) {
    .slider-fullscreen {
      height: 50vh;
    }
  
    .slide-caption {
      bottom: 5px;
      left: 5px;
      padding: 6px;
    }
  
    .slide-caption h2 {
      font-size: 1em;
    }
  
    .slide-caption p {
      font-size: 0.8em;
    }
  } */
/* SLIDER END */


/* Home start */
.section-counter{
  /* background-color: #fff8e1; */
  padding: 30px;
 
  margin-bottom: 30px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.welcome-text{
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
   border-radius: 10px;
   background-color: #fff8e1;
   padding-bottom: 20px;
}
@media (max-width: 768px){
  .welcome-text{
    align-items: center;
  }
}
.section-counter h2,
.section-counter h3 {
  color: #d2691e;
  font-weight: bold;
  font-family: 'Georgia', serif;
}

/* Home end */

/* about us */
/* General Styles */


.site-section {
  padding: 50px 0;
}

.section-header {
  background-color: #fff8e1;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.about-us .text-justify {
  text-align: justify;
}

.about-us .section-header h2,
.about-us .section-header h3 {
  color: #d2691e;
  font-weight: bold;
  font-family: 'Georgia', serif;
}

a {
  color: #d2691e;
}

a:hover {
  color: #b35900;
  text-decoration: none;
}

/* Team Section */
.team-member {
  background-color: #fff8e1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.team-member:hover {
  transform: translateY(-10px);
}

.team-member h4 {
  color: #d2691e;
  font-weight: bold;
  font-family: 'Georgia', serif;
}

.team-member .text-muted {
  color: #6c757d !important;
}

.team-member img {
  border: 5px solid #d2691e;
}

.btn-white {
  background-color: #fff8e1;
  border: 2px solid #d2691e;
  color: #d2691e;
  transition: background-color 0.3s, color 0.3s;
}

.btn-white:hover {
  background-color: #d2691e;
  color: #fff;
}

.link-underline {
  color: #d2691e;
  text-decoration: underline;
}

.link-underline:hover {
  color: #b35900;
}

/* we do */
/* Retro Style CSS */



/* General Styles */
.site-section {
  padding: 60px 0;
}
.plantationimg{
  height: 25%;
  width: 100%;
}

h2.retro-heading {
  font-family: 'Georgia', serif;
  font-size: 36px;
  color: #b35836;
  margin-bottom: 20px;
}

h3.retro-subheading {
  font-family: 'Georgia', serif;
  font-size: 30px;
  color: #a34d34;
  margin-bottom: 20px;
}

.retro-list {
  list-style-type: lower-alpha;
}

.retro-list li {
  margin-bottom: 10px;
}

.text-justify {
  text-align: justify;
}

a {
  color: #b35836;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .site-section {
    padding: 40px 20px;
  }

  .plantationimg{
    height: 11%;
    width: 100%;
  }  

  h2.retro-heading {
    font-size: 28px;
    margin-bottom: 15px;
  }

  h3.retro-subheading {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .retro-list li {
    margin-bottom: 8px;
  }
}

@media (max-width: 576px) {
  .site-section {
    padding: 20px 10px;
  }
  .plantationimg{
    height: 5%;
    width: 100%;
  }
  

  h2.retro-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }

  h3.retro-subheading {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .retro-list li {
    margin-bottom: 6px;
  }

  .text-justify {
    text-align: left; /* Justify text may not look great on small screens */
  }
}


/* membership */

.btn-apply{
  background-color: #b35836;
}