/* Retro Style CSS */

body {
    font-family: 'Courier New', Courier, monospace;
    background-color: #f8f1e4;
    color: #5b4636;
  }
  
  .retro-header {
    background-color: #ffefd5; /* PapayaWhip */
    padding: 10px 0;
    border-bottom: 2px solid #5b4636;
  }
  
  .retro-logo {
    max-height: 80px;
    border-radius: 50%;
    margin-left: 60%;
  }
  
  .retro-title p {
    font-family: 'Georgia', serif;
    font-size: 37px;
    color: #b35836;
    margin: 0;
  }
  
  .list-inline-item {
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .retro-icon {
    color: #5b4636;
    transition: color 0.3s;
  }
  
  .retro-icon:hover {
    color: #b35836;
  }
  .header-main{
    display: none;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    .retro-header .row{
      display: none;
    }
    .social-media{
      display: none;
    }
    .header-main{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .retro-logo {
      max-height: 80px;
      border-radius: 50%;
      margin-left: 60%;
    }
    
    .retro-title p {
      font-family: 'Georgia', serif;
      font-size: 37px;
      color: #b35836;
      margin: 0;
    }
    
    .list-inline-item {
      margin-left: 10px;
      margin-right: 10px;
    }
    
    .retro-icon {
      color: #5b4636;
      transition: color 0.3s;
    }
    
  }

  @media (max-width: 768px) {
    .retro-header .row{
      display: none;
    }
    .social-media{
      display: none;
    }
    .header-main{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .retro-logo {
      max-height: 80px;
      border-radius: 50%;
      margin-left: 0%;
      align-items: center;
    }
    
    .retro-title p {
      font-family: 'Georgia', serif;
      font-size: 20px;
      color: #b35836;
      margin: 0;
    }
    
    .list-inline-item {
      margin-left: 10px;
      margin-right: 10px;
    }
    
    .retro-icon {
      color: #5b4636;
      transition: color 0.3s;
    }
    
  }

  /* navbar */
  .retro-name{
    font-size: 20px;
    text-align: center;
  }
  .donation-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}

  /* Retro Style CSS */

body {
    font-family: 'Courier New', Courier, monospace;
    background-color: #f8f1e4;
    color: #5b4636;
  }
  
  .custom-navbar {
    background-color: #ffefd5; /* PapayaWhip */
    border-bottom: 2px solid #5b4636;
  }
  
  .navbar-toggler {
    border-color: #b35836;
  }
  
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%2864, 64, 64, 0.7%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  
  .navbar-brand .logo-btn {
    font-family: 'Courier New', Courier, monospace;
    color: #b35836;
    text-transform: uppercase;
    border: 2px solid #b35836;
    padding: 5px 10px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .navbar-brand .logo-btn .donate-text {
    font-size: 16px;
    font-weight: bold;
  }
  
  .navbar-brand .logo-btn:hover {
    background-color: #b35836;
    color: #ffefd5;
  }
  
  .nav-link {
    font-family: 'Georgia', serif;
    font-size: 18px;
    color: #5b4636;
    text-transform: uppercase;
    margin: 0 10px;
    transition: color 0.3s;
  }
  
  .nav-link:hover {
    color: #b35836;
  }
  
  /* Responsive styling */
  @media (max-width: 1200px) {
    .navbar-brand .logo-btn .donate-text {
      font-size: 14px;
    }
  
    .nav-link {
      font-size: 16px;
      margin: 0 8px;
    }
  }
  
  @media (max-width: 768px) {
    .nav-link {
      font-size: 14px;
      margin: 0 5px;
    }
  }
  
  @media (max-width: 576px) {
    .navbar-brand .logo-btn .donate-text {
      font-size: 12px;
    }
  
    .nav-link {
      font-size: 12px;
      margin: 0 3px;
    }
  }
  
  

  /*  about */
  .contact{
    background-color: #fff8e1

  }

  .site-section.contact-us {
    padding: 80px 0;
  }
  
  .section-title{
    color: #d2691e;
    font-weight: bold;
    font-family: 'Georgia', serif;
    margin-top: 30px;
    
  }

  .contact-title {
    font-family: 'Courier New', Courier, monospace;
    font-size: 36px;
    color: #814f4f;
    
  }

  
  /* .visit{
    background-color: #fff8e1;


  } */
  form .section-description {
    font-family: 'Georgia', serif;
    font-size: 18px;
    color: #814f4f;
  }
  
  form .retro-text {
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    color: #814f4f;
  }


 form .retro-input {
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    color: #814f4f;
    background-color: #fff;
    border: 1px solid #814f4f;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 15px;
  }


  .section-description {
    font-family: 'Courier New', Courier, monospace;
    font-size: 18px;
    color: #814f4f;
  }
  
  .retro-text {
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    color: #814f4f;
  }
  
  
  .retro-input {
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    color: #814f4f;
    background-color: #fff;
    border: 1px solid #814f4f;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 15px;
  }
  
form .retro-btn {
    font-family: 'Courier New', Courier, monospace;
    font-size: 18px;
    color: #fff;
    background-color: #814f4f;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
 form .retro-btn:hover {
    background-color: #6a3c3c;
  }
  
  .contact-g-info{
    padding: 20px;
 
  }

  .contact-info {
    background-color: #fff8e1;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    height: 100%
  }
  
  .map {
    margin-top: 30px;
  }
  
  .map iframe {
    width: 100%;
    border: 2px solid #814f4f;
    border-radius: 10px;
  }
  
/* fundraiselist */
/* src/Assets/RetroStyle.css */
/* 
.retro-title {
  font-family: 'Courier New', Courier, monospace;
  color: #ff9900;
  font-size: 2rem;
  text-shadow: 2px 2px #000;
}

.retro-link {
  color: #ff9900;
  text-decoration: underline;
}

.retro-card {
  background-color: #f4f4f4;
  border: 2px solid #000;
  border-radius: 5px;
}

.retro-image {
  border-bottom: 2px solid #000;
}

.retro-paragraph {
  font-family: 'Courier New', Courier, monospace;
  color: #333;
} */


/* footer */

.site-footer {
  background-color: #333;
  color: #fff;
  padding: 40px 0;
}

.site-footer h3 {
  color: #f7f7f7;
  font-size: 20px;
  margin-bottom: 20px;
}

.site-footer ul {
  padding: 0;
  list-style: none;
}

.site-footer ul li {
  margin-bottom: 10px;
}

.site-footer ul li a {
  color: #fff;
  text-decoration: none;
}

.site-footer ul li a:hover {
  text-decoration: underline;
}

.site-footer .social {
  display: flex;
  gap: 15px;
}

/* .retro-icon {
  color: #fff;
  font-size: 24px;
} */

.retro-link {
  color: #ffc107;
  text-decoration: none;
}

.retro-link:hover {
  text-decoration: underline;
}


  @media (max-width: 768px) {
    .map iframe {
      height: 300px;
    }
  }
  

  /* popup */

  /* General styling for the custom form */
.custom-form {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffefd5; /* PapayaWhip */
  border: 1px solid #5b4636;
  border-radius: 5px;
  font-family: 'Courier New', Courier, monospace;
  color: #5b4636;
}

.custom-form .form-control {
  border-color: #5b4636;
  background-color: #fffaf0; /* FloralWhite */
  color: #5b4636;
  font-family: 'Georgia', serif;
}

.custom-form .form-control:focus {
  border-color: #b35836;
  box-shadow: none;
}

.custom-form .form-label {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #b35836;
}

.custom-form .btn-primary {
  background-color: #b35836;
  border-color: #b35836;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px 20px;
  transition: background-color 0.3s, border-color 0.3s;
}

.custom-form .btn-primary:hover {
  background-color: #5b4636;
  border-color: #5b4636;
}

/* Responsive styling */
@media (max-width: 1200px) {
  .custom-form .form-label {
    font-size: 16px;
  }

  .custom-form .btn-primary {
    font-size: 14px;
    padding: 8px 16px;
  }
}

@media (max-width: 768px) {
  .custom-form .form-label {
    font-size: 14px;
  }

  .custom-form .btn-primary {
    font-size: 12px;
    padding: 6px 12px;
  }
}

@media (max-width: 576px) {
  .custom-form .form-label {
    font-size: 12px;
  }

  .custom-form .btn-primary {
    font-size: 10px;
    padding: 5px 10px;
  }
}

/* Modal styling */
.modal-content {
  background-color: #fffaf0; /* FloralWhite */
  padding: 20px;
  border: 1px solid #5b4636;
  border-radius: 5px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-body {
  padding: 20px;
}

.marquee-wrapper {
  background: linear-gradient(90deg, rgba(255,0,150,1) 0%, rgba(0,204,255,1) 100%);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
}

.marquee-container {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: white;
}

.marquee-link {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  margin: 0 20px;
  transition: color 0.3s;
}

.marquee-link:hover {
  color: #ffdd00;
}

.marquee-icon {
  color: #ff0000;
  font-size: 24px;
  margin: 0 15px;
  transition: transform 0.3s;
}

.marquee-icon:hover {
  transform: scale(1.2);
}

/* Responsive Design */

@media (max-width: 768px) {
  .marquee-container {
    font-size: 16px;
  }

  .marquee-link {
    margin: 0 15px;
  }

  .marquee-icon {
    font-size: 20px;
    margin: 0 10px;
  }
}

@media (max-width: 480px) {
  .marquee-container {
    font-size: 14px;
  }

  .marquee-link {
    margin: 0 10px;
  }

  .marquee-icon {
    font-size: 18px;
    margin: 0 5px;
  }
}

/* Style.css */
/* Ensure the slider container and images are responsive */
.slider-fullscreen {
  width: 100%;
  overflow: hidden;
}

.slider-image {
  width: 100%;
  display: block; /* Remove default inline-block space */
  object-fit: cover; /* Ensure image covers the area without distortion */
}

/* Adjust max-height based on different screen sizes */
@media (max-width: 1024px) {
  .slider-fullscreen {
    height: 50%;
  }
  .slider-image {
    max-height: 500px;
    object-fit: cover; /* Adjust as needed for tablets */
  }
}

@media (max-width: 600px) {
  .slider-fullscreen {
    height: 50%;
  }
  .slider-image {
    max-height: 200px;
    object-fit: cover; /* Adjust as needed for mobile devices */
  }
}

/* galley */
/* RetroStyle.css or any other CSS file */

.card-img-container {
  position: relative;
  width: 100%;
  padding-top: 75%; /* Adjust the padding to maintain aspect ratio (e.g., 75% for 4:3 ratio) */
  overflow: hidden;
}

.card-img-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures images cover the container */
}

.visitor{
  width: 55%;
}

.visitor-tittle{
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 5%;
}

a .visitor-tittle{
  color: #ff0000;
}
